@media (max-width: 576px) {
    div.tourup{
        width: 92%;
        text-align: right;
        margin: 128px auto 0 auto !important;
        padding: 0 !important;
    }
    .maptour_guide{
        margin: 15px 10px 0px 0px !important;
        right: 0 !important;
        position: fixed !important;
        top: 0 !important;
        z-index: 999 !important;
        width: 100%;
        text-align: right;
        max-width: 930px;
    }
    h1[class="guide_up"]{
        font-size: 16px;
        display: inline-flex;
        padding-right: 15px;
        vertical-align: middle;
        margin: 0 !important;
    }
    h1[class="guide_up"] span{
        display: none;
    }
    .ant-btn.ant-btn.m33-btn.ant-btn-secondary.ant-btn-lg.guide_up{
        font-size: 13px;
        height: 28px !important;
        display: inline-flex;
        vertical-align: middle;
    }
    div[id^="react-joyride-step"],
    div[id^="react-joyride-step"] div{
        z-index: 1000!important;
    }
} 

@media(min-width: 576px) {
    div.tourup{
        width: 100%;
        text-align: right;
        margin: 128px auto 0 auto !important;
        padding: 0 !important;
        max-width: 510px;
    }
    .maptour_guide{
        margin: 15px 10px 0px 0px !important;
        right: 0 !important;
        position: fixed !important;
        top: 0 !important;
        z-index: 999 !important;
        width: 100%;
        text-align: right;
        max-width: 510px;
    }
    h1[class="guide_up"] span{
        display: none;
    }
    h1[class="guide_up"]{
        font-size: 18px;
        display: inline-flex;
        padding-right: 15px;
        vertical-align: middle;
        margin: 0 !important;
    }
    .ant-btn.ant-btn.m33-btn.ant-btn-secondary.ant-btn-lg.guide_up{
        font-size: 15px;
        height: 30px !important;
        display: inline-flex;
        vertical-align: middle;
    }
    div[id^="react-joyride-step"],
    div[id^="react-joyride-step"] div{
        z-index: 1000!important;
    }
} 

@media (min-width: 768px) {
    div.tourup{
        width: 100%;
        text-align: right;
        margin: 5px auto 0 auto !important;
        padding: 0 !important;
        max-width: 690px;
    }
    .maptour_guide{
        margin: 15px 10px 0px 0px !important;
        right: 0 !important;
        position: fixed !important;
        top: 0 !important;
        z-index: 999 !important;
        width: 100%;
        text-align: right;
        max-width: 690px;
    }
    h1[class="guide_up"] span{
        display: inline-block;
    }
    h1[class="guide_up"]{
        font-size: 18px;
        display: inline-flex;
        padding-right: 15px;
        vertical-align: middle;
        margin: 0 !important;
    }
    .ant-btn.ant-btn.m33-btn.ant-btn-secondary.ant-btn-lg.guide_up{
        font-size: 15px;
        height: 30px !important;
        display: inline-flex;
        vertical-align: middle;
    }
    div[id^="react-joyride-step"],
    div[id^="react-joyride-step"] div{
        z-index: 1000!important;
    }
} 

@media (min-width: 992px) {    
    div.tourup{
        width: 100%;
        text-align: right;
        margin: 5px auto 0 auto !important;
        padding: 0 !important;
        max-width: 930px;
    } 
    .maptour_guide{
        margin: 15px 10px 0px 0px !important;
        right: 0 !important;
        position: fixed !important;
        top: 0 !important;
        z-index: 999 !important;
        width: 100%;
        text-align: right;
        max-width: 930px;
    }
    h1[class="guide_up"]{
        font-size: 18px;
        display: inline-flex;
        padding-right: 15px;
        vertical-align: middle;
        margin: 0 !important;
    }
    h1[class="guide_up"] span{
        display: inline-block;
    }
    .ant-btn.ant-btn.m33-btn.ant-btn-secondary.ant-btn-lg.guide_up{
        font-size: 15px;
        height: 30px !important;
        display: inline-flex;
        vertical-align: middle;
    }
    div[id^="react-joyride-step"],
    div[id^="react-joyride-step"] div{
        z-index: 1000!important;
    }
} 

@media (min-width: 1200px) {
    .maptour_guide{
        margin: 15px 10px 0px 0px !important;
        right: 0 !important;
        position: fixed !important;
        top: 0 !important;
        z-index: 999 !important;
        width: 100%;
        text-align: right;
        max-width: 1108px !important;
    }
    div.tourup{
        width: 100%;
        text-align: right;
        margin: 5px auto 0 auto !important;
        padding: 0 !important;
        max-width: 1108px !important;
    }
    h1[class="guide_up"]{
        font-size: 18px;
        display: inline-flex;
        padding-right: 15px;
        vertical-align: middle;
        margin: 0 !important;
    }
    h1[class="guide_up"] span{
        display: inline-block;
    }
    .ant-btn.ant-btn.m33-btn.ant-btn-secondary.ant-btn-lg.guide_up{
        font-size: 15px;
        height: 30px !important;
        display: inline-flex;
        vertical-align: middle;
    }
    div[id^="react-joyride-step"],
    div[id^="react-joyride-step"] div{
        z-index: 1000!important;
    }
}


#react-joyride-step-3{
    position: absolute !important;
    width: 100% !important;
    top: 130px !important;
}
.react-joyride__tooltip button{
        background-color: rgb(240, 115, 168) !important;
    border: 1px #ffffff solid !important;
    border-radius: 15px !important;
    color: rgb(255, 255, 255) !important;
    width: 100% !important;
    font-weight: 500 !important;
    height: 32px;
    
}
.react-joyride__tooltip button:focus {
  outline: none;
}