body,
html {
    padding: 0;
    margin: 0;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
}

/*Grid*/
.container {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.col {
    display: flex;
    flex-direction: column;
}

/*Helpers*/
.flex-1 {
    flex: 1;
    height: 70vh;
}

.flex-2 {
    flex: 1;
}

.middle {
    justify-content: center;
}

.center {
    align-items: center;
}

/*Font*/
.bold {
    font-weight: 700;
}

.title {
    font-size: 80px;
}

.txt-uppercase {
    text-transform: uppercase;
}

/*Buttons*/
.button {
    background: #ffffff;
    padding: 5px 10px;
    border-radius: 3px;
    border: 0;
}

.button:hover {
    cursor: pointer;
}


.circular-button {
    border-radius: 100%;
    height: 60px;
    width: 60px;
    border: 2px solid #ffffff;
    color: #ffffff;
    background: rgba(24, 26, 25, 0.5);
    font-size: 30px;
    -webkit-transition: background-color .125s;
    -moz-transition: background-color .125s;
    -o-transition: background-color .125s;
    transition: background-color .125s;
}

.circular-button:hover {
    cursor: pointer;
    background: rgba(0,0,0,0.75);
}

/*Sidebar*/
.sidebar .button {
    display: flex;
    flex-direction: row;
    border-radius: 0;
    padding: 25px;
    justify-content: center;
    font-size: 16px;
    -webkit-transition: background .125s;
    -moz-transition: background .125s;
    -o-transition: background .125s;
    transition: background .125s;
}

.sidebar .button:hover {
    background: #121211;
}

.sidebar .button:first-child {
    -webkit-border-top-left-radius: 25px;
    -moz-border-radius-topleft: 25px;
    border-top-left-radius: 25px;
}

.sidebar .button:last-child {
    -webkit-border-bottom-left-radius: 25px;
    -moz-border-radius-bottomleft: 25px;
    border-bottom-left-radius: 25px;
}

/*Borders*/
.border-top {
    border-top: 1px solid #181a19
}

.border-bottom {
    border-bottom: 1px solid #181a19
}

.border-left {
    border-left: 1px solid #181a19
}

.border-right {
    border-right: 1px solid #181a19
}

/*Padding*/
.p-1 {
    padding: 10px;
}

.p-2 {
    padding: 20px;
}

.pb-1 {
    padding-bottom: 10px;
}

.pt-2 {
    padding-top: 20px;
}

.pr-2 {
    padding-right: 20px;
}

.pl-2 {
    padding-left: 20px;
}

.pb-2 {
    padding-bottom: 20px;
}
/*Margins*/
.m-1 {
    margin: 10px;
}

.m-4 {
    margin: 40px;
}

.mr-1 {
    margin-right: 10px;
}

.mr-2 {
    margin-right: 20px;
}

.ml-1 {
    margin-left: 5px;
}

.ml-2 {
    margin-left: 20px;
}

.mb-1 {
    margin-bottom: 10px;
}

.mb-2 {
    margin-bottom: 20px;
}

/*Text Colors*/
.txt-white {
    color: #ffffff;
}
.text-muted {
    color: #666;
}

/*Background Colors*/
.bg-green {
    background: #78c79b;
}

.bg-white {
    background: #ffffff;
}

.bg-pink {
    background: #E36D9D;
}

.bg-orange {
    background: #E78163;
}

/*Map*/
.map {
    flex: 1;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
}